import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-modal-routing-3'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import Card from '../components/card'

const Grid = styled.section`
display: grid;
grid-template-columns: repeat(2, 18.5vw);
grid-auto-rows: minmax(min-content, max-content);
grid-column-gap: 6vw;
background-color: #fff;
padding: 18px 5vw 0 0;
@media (max-width: 599px) {
  grid-template-columns: repeat(2, 43vw);
  img {
    max-width: 43vw;
    max-height: 43vw;
    height: 43vw;
    width: 43vw !important;
  }
}
h1 {
    grid-column: 1/3;
    line-height: 1.15em;
    font-size: 1.0625rem;
    font-weight: 700;
    letter-spacing: .75px;
    height: 8vh;
}
> div {
  margin-bottom: 5vh;
}
`
const Title = styled.h1`

`

const Newslist = () => {
    const data = useStaticQuery(graphql`
    {
        allContentfulNews(sort: {fields: date, order: DESC}) {
          nodes {
            id
            date(formatString: "MMM D, YYYY")
            slug
            title
            image {
              gatsbyImageData
            }
          }
        }
      }      
    `)
    return (
        <Grid>
            <Title>News</Title>
            {data.allContentfulNews.nodes.map(node => (
                <Card key={node.id}><Link to={`/news/${node.slug}`} asModal><div><GatsbyImage image={node.image.gatsbyImageData} alt="image" objectFit="cover" /><h3>{node.title}</h3><address>{node.date}</address></div></Link></Card>
            ))}
        </Grid>
    )
}

export default Newslist